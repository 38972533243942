@use "styles/colors";
@use "styles/theme";

// --------------------------------------------------------------------
// CONTAINER
// --------------------------------------------------------------------
.container {
  margin-top: 1rem;
  border-top: 1px solid colors.$gray-light;
  padding-top: 4rem;
  display: grid;
  gap: 5rem;

  @media (max-width: theme.$breakpoint-sm) {
    gap: 4rem;
  }
}

// --------------------------------------------------------------------
// SECTION
// --------------------------------------------------------------------

section.section {
  h2 {
    margin: 0 0 1.5rem 0;
  }

  p {
    max-width: 100ch;
  }
}

// --------------------------------------------------------------------
// STATISTICS
// --------------------------------------------------------------------
ul.statistics {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 3px;
  padding: 0;
  margin: 0;

  li.statistic {
    text-align: center;
    background-color: colors.$secondary-muted;
    padding: 1rem;

    span.value {
      display: block;
      font-size: 2rem;
      font-weight: bold;
      color: colors.$secondary;
    }

    span.label {
      font-size: 0.9rem;
    }
  }

  li.statistic:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  li.statistic:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  @media (max-width: theme.$breakpoint-sm) {
    grid-template-columns: 1fr;

    li.statistic:first-child {
      border-bottom-left-radius: 0px;
      border-top-right-radius: 8px;
    }

    li.statistic:last-child {
      border-top-right-radius: 0px;
      border-bottom-left-radius: 8px;
    }
  }
}

// --------------------------------------------------------------------
// BLOG
// --------------------------------------------------------------------
ul.blogPostsContainer {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 3rem 1.5rem;
  justify-items: center;

  li.blogPost {
    max-width: 50ch;

    img {
      border-radius: 3px;
    }

    h4 {
      margin-block: 0.5rem; 
    }

    div.excerpt {
      text-align: justify;
    }
  }

  @media (max-width: theme.$breakpoint-sm) {
    grid-template-columns: 1fr;
  }
}

a.visit {
  color: colors.$secondary;
  text-decoration: none;
  font-weight: bold;
}

a.blogTitle {
  color: colors.$text;
  text-decoration: none;
}


// --------------------------------------------------------------------
// SOCIALS
// --------------------------------------------------------------------

.socials {
  margin: 0 auto;
  display: flex;
  gap: 1rem;

  .facebook:hover,
  .facebook:focus {
    path {
      fill: #1877F2;
    }
  }

  .linkedin:hover,
  .linkedin:focus {
    path {
      fill: #0A66C2;
    }
  }
}

// --------------------------------------------------------------------
// GRID
// --------------------------------------------------------------------
div.grid {
  display: grid;
  gap: 1.5rem;

  @media (max-width: theme.$breakpoint-sm) {
    grid-template-columns: minmax(0px, 1fr) !important;
  }
}

// --------------------------------------------------------------------
// PLUS
// --------------------------------------------------------------------
div.plus {
  background-color: colors.$tertiary-muted;
  padding: 1.5rem;
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1.5rem 3rem;

  @media (max-width: theme.$breakpoint-sm) {
    grid-template-columns: 1fr;
  }

  img {
    border-radius: 5px;
  }

  div.left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p.or {
      text-align: center;
    }

    p {
      margin: 0;
    }

    ul {
      list-style: none;
      padding-left: 4rem;
      margin: 0.5rem 0;
    }

    li {
      position: relative;
      padding: 0.6rem 0;
      font-family: theme.$font-serif;
    }

    li::before {
      content: '\2713';
      position: absolute;
      width: 1.75rem;
      height: 1.75rem;
      display: grid;
      place-items: center;
      top: 0.2rem;
      left: -3rem;
      border: 3px solid colors.$secondary;
      color: colors.$secondary;
      background-color: colors.$tertiary;
      border-radius: 50%;
    }
  }
}

// --------------------------------------------------------------------
// CARD
// --------------------------------------------------------------------
div.card {
  background-color: white;
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid colors.$gray-medium;
  
  h3 {
    margin: 0 0 0.5rem 0;
  }
}

.personalUpdates {
  grid-column: span 2;

  p {
    margin-bottom: 0;
  }

  ul {
    margin-top: 0.5rem;
  }

  @media (max-width: theme.$breakpoint-sm) {
    grid-column: span 1;
  }
}

.subscriptions {
  p {
    margin-bottom: 0;
  }
}

// --------------------------------------------------------------------
// FEATURED PROFILES
// --------------------------------------------------------------------
.featuredProfiles {
  overflow: hidden;
  height: fit-content;
  h3 {
    margin-bottom: 1rem !important;
  }
}

ul.referenzList {
  list-style: none;
  margin: 0 0 0.5rem;
  padding: 0;

  li {
    padding: 0.5rem 0;
    border-bottom: 1px solid colors.$gray-light;
    overflow: hidden;
  }

  li:first-child { padding-top: 0; }
  li:last-child { border-bottom: none; }

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color: colors.$text;
    overflow: hidden;
    
    &:hover, &:focus {
      color: colors.$secondary;
    }
  }

  :global(.icon) {
    flex-shrink: 0;
    margin-right: 0.5rem;
  }

  span.name {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// --------------------------------------------------------------------
// NEWSLETTER
// --------------------------------------------------------------------
.newsletter {
  input:not([type=checkbox]) {
    width: 100%;
    margin-bottom: 1rem;
  }

  .legal {
    label {
      font-size: 0.8rem;
    }
  }

  button {
    gap: 0.5rem;
  }

  .statusMessage {
    background-color: colors.$primary;
    color: colors.$primary-foreground;
    border-radius: 10px;
    padding: 0.5em 1em;
  }
}

// --------------------------------------------------------------------
// STEPS CTA
// --------------------------------------------------------------------

section.stepsCta {
  button { width: 100%; }

  ol {
    counter-reset: orderedlist;
    list-style: none;
    padding-left: 3rem;
    display: grid;
    gap: 1rem;
    margin: 1rem 0 2rem 0;

    li {
      position: relative;
      padding: 0.5rem 0;
    }

    li::before {
      counter-increment: orderedlist;
      content: counter(orderedlist);
      position: absolute;
      width: 2rem;
      height: 2rem;
      display: grid;
      place-items: center;
      top: 0.2rem;
      left: -3rem;
      border: 1px solid colors.$gray;
      border-radius: 50%;
    }
  }
}

// --------------------------------------------------------------------
// ABOUT TEXT
// --------------------------------------------------------------------

section.about {
  p {
    text-align: justify;
    line-height: 1.6;
  }
}

// --------------------------------------------------------------------
// SOURCES
// --------------------------------------------------------------------
div.sources {
  display: flex;
  flex-direction: column;

  h3 {
    background-color: colors.$secondary-muted;
    margin: 0;
    padding: 0.5rem 1rem;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    font-size: 1rem;
  }

  ul {
    flex: 1;
    list-style: none;
    padding: 1rem;
    margin: 0;
    border: 1px solid colors.$gray-light;
    background-color: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  li::before {
    content: '\2713';
    font-size: 1rem;
    display: inline-block;
    margin-right: 0.5em;
  }
}

// --------------------------------------------------------------------
// DATA INFO
// --------------------------------------------------------------------
div.dataInfo {
  grid-column: span 2;
  display: flex;
  flex-direction: column;

  h3 {
    background-color: colors.$secondary-muted;
    margin: 0;
    padding: 0.5rem 1rem;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    font-size: 1rem;
    width: calc(50% - 0.75rem);
  }

  ul {
    flex: 1;
    list-style: none;
    padding: 1rem;
    margin: 0;
    border: 1px solid colors.$gray-light;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 0 2rem;
    background-color: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }

  li::before {
    content: '\2022';
    font-size: 1rem;
    display: inline-block;
    margin-right: 0.5em;
  }

  @media (max-width: theme.$breakpoint-md) {
    ul {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    h3 {
      width: 50%;
    }
  }

  @media (max-width: theme.$breakpoint-sm) {
    grid-column: span 1;
  }

  @media (max-width: theme.$breakpoint-xs) {
    ul {
      grid-template-columns: 1fr;
      border-top-right-radius: 0px;
    }
    h3 {
      width: 100%;
    }
  }
}