@use "../../../styles/theme";
@use "../../../styles/colors";

.contentRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0 -1rem;
  padding: 1rem;
  scroll-margin-top: 109px;

  @media screen and (max-width: theme.$breakpoint-md) {
    flex-direction: column;
  }
}

.groupItem {
  border-bottom: 1px solid colors.$gray-light;

  &:nth-last-child(2) {
    border: none;
  }
}

.content {
  width: 100%;
  max-width: calc(100% - 17rem);
  align-self: center;

  @media screen and (max-width: theme.$breakpoint-md) {
    max-width: 100%;
  }
}

.titleContainer {
  width: 17rem;
  flex-shrink: 0;
  padding-right: 0.5rem;

  @media screen and (max-width: theme.$breakpoint-md) {
    margin-bottom: 0.5rem;
    br {
      display: none;
    }
  }

  span {
    font-family: theme.$font-serif;
    color: colors.$secondary;
  }
}

.actions {
  margin: 0rem -1rem;
  padding: 0rem 1rem 0.5rem 1rem;
  border-bottom: 1px solid colors.$secondary;

  button {
    color: colors.$secondary;
    display: inline-block;
    text-align: center;
    background-color: transparent;
    outline: none;
    border: none;

    &:hover {
      cursor: pointer;
    }

    span {
      display: block;
      margin-top: -0.75em;
      font-size: 0.65rem;
    }
  }
}
