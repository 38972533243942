@use "styles/colors";
@use "styles/theme";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 900px;
  margin: 3rem auto;
  color: colors.$gray;

  @media (max-width: theme.$breakpoint-sm)
  {
    flex-direction: column;
  }

  h2 {
    font-family: theme.$font-regular;
    color: colors.$text;
    font-weight: bold;
    font-size: 2rem;
    margin: 0.5rem 0;
  }

  .iconContainer {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    border: 10px solid colors.$secondary;
    background-color: colors.$tertiary;
    font-size: 4rem;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    color: colors.$secondary;
    margin-right: 2rem;
  }
}