@use "../../../styles/colors";

.anchor {
  cursor: pointer;
}

.primary {
  text-decoration: underline;
  color: inherit;
}

.secondary {
  text-decoration: none;
  color: colors.$secondary;
}

.subtle {
  text-decoration: none;
  color: inherit;
}

.subtle:hover {
  text-decoration: underline;
  color: colors.$secondary;
}

.asButton {
  text-decoration: none !important;
  text-align: center;
}

.foreign {
  text-decoration: none !important;
  cursor: auto;
}