@use "../../../styles/colors";
@use "../../../styles/theme";

// --------------------------------
// Searchbar
// --------------------------------

.container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.searchBar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid colors.$gray;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2) inset;
  font-size: 1.25rem;
  line-height: 1.5;
  background-color: white;

  span.searchStatus {
    position: absolute;
    right: 0;
    width: 2.5em;
    height: 2.25rem;
    flex-shrink: 0;
    color: colors.$secondary;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    pointer-events: none;

    &.clickable {
      pointer-events: auto;

      &:hover {
        cursor: pointer;
      }
    }
  }

  > input {
    flex: 1;
    padding: 1.4rem 1rem 1.3rem 1rem;
    background: transparent;
    border: none;
    line-height: 1.3;
    border-radius: 0;
    box-shadow: none;
    font-family: theme.$font-serif;

    &::placeholder {
      color: colors.$secondary;
      font-size: 1.25rem;
    }
  }
}

// --------------------------------
// Search Results
// --------------------------------

.searchContainer {
  margin-top: 1rem;
}

.searchResultContainer {
  max-height: 20rem;
  overflow-y: auto;
  margin-bottom: 1.5rem;
}

.searchResult {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: 1.1rem;
  font-family: theme.$font-serif;
  border-bottom: 1px solid colors.$gray-light;
  padding: 0.5rem 0rem;
  color: colors.$text;
  text-decoration: none;
  outline: none;

  &:last-child {
    border-bottom: none;
  }

  &.highlighted, &:focus {
    color: colors.$secondary;
  }

  span.icon {
    width: 25px;
    flex-shrink: 0;
    margin-right: 1rem;
    position: relative;

    :global(.icon) {
      height: 1.4em;
      width: 1.4em;
      position: absolute;
      top: 0;
    }
  }
}

.buttonContainer {
  border-top: 1px solid colors.$secondary;
  margin: 0 -3rem;
  padding: 0.5rem 3rem 0 3rem;

  span.term {
    color: colors.$secondary;
    font-family: theme.$font-serif;
  }

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  :global(.icon) {
    margin-right: 0.5rem;
  }

  button {
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }
}

// --------------------------------
// Grouped Search Results
// --------------------------------

span.status {
  display: inline-block;
  font-size: 0.7em;
  font-family: theme.$font-regular;
  padding: 5px 10px;
  border-radius: 5px;
  color: white;

  &.Aktiv {
    background-color: #479847;
  }

  &.InLiquidation {
    background-color: #e8de1a;
    color: black;
  }

  &.Gelöscht {
    background-color: #CA0909;
  }

  &.Umbenannt {
    background-color: #E6E7EF;
    color: black;
  }
}

.groupedSearchResult {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0.5rem 0rem;
  font-family: theme.$font-serif;
  font-size: 1.1rem;
  border-bottom: 1px solid colors.$gray-light;

  &:last-child {
    border: none;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  div.statusContainer {
    display: inline-block;
    width: 4rem;
    margin-right: 1rem;
    flex-shrink: 0;
  }

  div.pastNamesContainer {
    color: colors.$gray;

    a {
      display: block;
    }
  }
}