@use "colors";
@use "theme";

// ----------------------------------------
// Buttons
// ----------------------------------------

button {
  color: colors.$text;
}


// ----------------------------------------
// Inputs
// ----------------------------------------

input {
  padding: 0.7rem 0.7rem;
  outline: none;
  border: 1px solid colors.$gray;
  border-radius: 3px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2) inset;

  &::placeholder {
    color: colors.$gray;
    font-family: theme.$font-serif;
    font-size: 0.8rem;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

input[type=checkbox] {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2) inset;
}

// ----------------------------------------
// Toggle
// ----------------------------------------

input[type=checkbox].toggle {
  width: 30px;
  height: 0px;
  visibility: hidden;

  &::before {
    content: '';
    position: absolute;
    display: inline-block;
    width: 30px;
    height: 16px;
    border-radius: 9999px;
    border: 1px solid colors.$gray;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2) inset;
    background-color: colors.$background;
    top: 3px;
    cursor: pointer;
    transition: .25s;
    visibility: visible;
  }

  &::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: colors.$secondary;
    top: 6px;
    left: 3px;
    cursor: pointer;
    transition: .25s;
    visibility: visible;
  }
}

input[type=checkbox].toggle:checked {
  &::before {
    background-color: colors.$secondary;
    border-color: colors.$secondary;
    box-shadow: none;
  }

  &::after {
    background-color: white;
    left: 17px;
  }
}

// ----------------------------------------
// Range Slider
// ----------------------------------------

input[type=range] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 3px;
  background: colors.$gray-light;
  outline: none;
  opacity: 1;
  border-radius: 10px;
  box-shadow: none;
  border: none;
  padding: 0.15rem 0rem;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    background: colors.$secondary;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    background: colors.$secondary;
    cursor: pointer;
  }
}


// ----------------------------------------
// Dropdown (select)
// ----------------------------------------

select {
  border-width: 1px;
  border-style: solid;
  border-color: colors.$primary;
  background-color: white;
  color: colors.$text;
  padding: 0.5em 3em 0.5em 1em;
  border-radius: 5px;
  font-size: 1rem;
  display: inline-block;
  appearance: none;
  margin: 0;
  line-height: 1.3;
  box-sizing: border-box;

  background-image: linear-gradient(60deg, transparent 50%, colors.$primary 50%),
  linear-gradient(120deg, colors.$primary 50%, transparent 50%);

  background-position: calc(100% - 21px) 50%,
  calc(100% - 15px) 50%,
  100% 0;

  background-size: 6px 12px,
  6px 12px,
  2.5em 2.5em;

  background-repeat: no-repeat;

  &:focus {
    outline: none;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

  @media print {
    display: none;
  }
}
