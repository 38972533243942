@use "styles/colors";

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  span {
    text-align: center;
  }
}

.small {
  svg {
    width: 1.2rem;
    height: 1.1rem;
  }
}

.large {
  span {
    flex-basis: 100%;
    margin-bottom: 0.5rem;
  }

  svg {
    width: 1.5rem;
    height: 1.4rem;
  }
}

.button {
  outline: none;
  border: none;
  background-color: transparent;
  display: inline-block;
  cursor: pointer;

  path {
    fill: colors.$text;
  }
}

.copy {
  path:nth-of-type(1) {
    fill: none;
  }

  // path:nth-of-type(2) {
  //   stroke: black;
  // }
  
  &:hover, &:focus {
    path:nth-of-type(2) {
      fill: colors.$secondary;
    }
  }
}

.facebook:hover,
.facebook:focus {
  path {
    fill: #1877F2;
  }
}

.whatsapp:hover,
.whatsapp:focus {
  path {
    fill: #00E676;
  }
}

.linkedin:hover,
.linkedin:focus {
  path {
    fill: #0A66C2;
  }
}

.xing:hover,
.xing:focus {
  path {
    fill: #006567;
  }
}