@use "styles/colors";

.cta {
  background-color: colors.$tertiary-muted;
  padding: 1.5rem 1rem;
  border-radius: 10px;
  margin-top: 4rem;
  position: relative;

  p {
    margin: 0 0 1rem 0;
    width: calc(100% - 7rem);
  }

  .störerContainer {
    position: absolute;
    top: -1.8rem;
    right: 1.5rem;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem 2rem;
    padding: 0 0.5rem;
  }

  button.login {
    background-color: transparent;
    text-decoration: underline;
    border: none;
    outline: none;
    cursor: pointer;
  }
}