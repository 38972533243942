// general
$background: #f9f9f9;
$text: #1f1e1e;
$destructive: #b00707;

// primary
$primary: rgba(36, 55, 55, 1);
$primary-muted: rgba(36, 55, 55, 0.7);
$primary-foreground: white;

// secondary
$secondary: rgba(91, 151, 152, 1);
$secondary-muted: #deeaea;

// tertiary
$tertiary: rgba(245, 190, 102, 1);
$tertiary-muted: #fbe5c2;
$tertiary-muted2: #F9D9A6;

// gray
$gray: #777C7E;
$gray-light: #E6E7EF;
$gray-medium: #cfd0d9;
$gray-dark: #3A3E3F;
