@use "styles/colors";
@use "styles/theme";

.störer {
  width: 6rem;
  height: 6rem;
  transform: rotate(15deg) scale(0.8);

  .text {
    position: absolute;
    top: 0;
    left: 0rem;
    width: 6rem;
    height: 6rem;
    z-index: 10;
    font-family: theme.$font-serif;
    line-height: 1.2;
    font-size: 1.1rem;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    color: colors.$primary;
    text-align: center;
  }
}

.shape {
  background: colors.$tertiary;
  width: 6rem;
  height: 6rem;
  position: relative;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

  &:after, &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 6rem;
    width: 6rem;
    background: colors.$tertiary;
  }

  &:before { transform: rotate(30deg); }
  &:after { transform: rotate(60deg); }
}