// fonts
$font-size: 16px;
$font-size-sm: 14px;
$font-regular: 'Source Sans Pro';
$font-serif: 'Merriweather';
$font-mono: 'Courier New, monospace';

// breakpoints
$breakpoint-xs: 450px;
$breakpoint-sm: 580px;
$breakpoint-md: 800px;
$breakpoint-lg: 1000px;
