@use "../../../styles/theme";
@use "../../../styles/colors";

.container {
  position: fixed;
  top: 1rem;
  right: 1rem;
  max-width: 25%;
  z-index: 200;

  @media (max-width: theme.$breakpoint-md) {
    max-width: 50%;
    top: 1rem;
    right: 1rem;
  }

  @media (max-width: theme.$breakpoint-sm) {
    max-width: unset;
    top: 1rem;
    right: 1rem;
    left: 1rem;
  }
}

.toast {
  background-color: colors.$primary;
  color: colors.$primary-foreground;
  border-radius: 10px;
  font-size: 1rem;
  padding: 0.5em 1em;
  margin-bottom: 0.5rem;
  width: 100%;
  animation: fade-in-left 0.5s ease-out;

  @media (max-width: theme.$breakpoint-sm) {
    animation: fade-in-down 0.5s ease-out;
  }

  @keyframes fade-in-left {
    from {
      opacity: 0;
      transform: translateX(100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes fade-in-down {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}