@use "styles/colors";
@use "styles/theme";

.footer {
  margin-top: 5rem;
  width: 100%;
}

.build {
  display: block;
  text-align: right;
  margin: 0 1rem 0.5rem 1rem;
  font-size: 0.9rem;
  color: colors.$gray;
}

div.footerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: colors.$primary;
  color: colors.$primary-foreground;
  padding: 3rem 1rem 2rem 1rem;


  &>a {
    margin-bottom: 1.5rem;
  }

}

nav.userAnchors {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  a {
    display: inline-block;
    margin: 0.5rem 1rem;
    text-decoration: none;
  }

  button {
    background-color: transparent;
    color: colors.$primary-foreground;
    border: none;
    padding: 0;
    margin: 0.5rem 1rem;
    line-height: 1.5;
    &:hover {
      cursor: pointer;
    }
  }
}

nav.anchors {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;

  a, button {
    display: inline-block;
    margin: 0.5rem 1rem;
    font-weight: 200;
    text-decoration: none;
    line-height: 1.5;
  }

  button {
    padding: 0;
    margin: 0.5rem 1rem;
    font-weight: 200;
    background-color: transparent;
    color: colors.$primary-foreground;
    border: none;

    &:hover {
      cursor: pointer;
    }
  }
}

div.footerSecondaryContainer {
  color: colors.$primary-foreground;
  width: 100%;
  font-weight: 100;
  padding: 1.5rem;
  font-size: 0.8rem;
  background-color: colors.$primary-muted;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem 5rem;
  text-align: center;

  .mediengruppe {
    span {
      display: block;
      font-family: theme.$font-mono;
      font-weight: bold;
      font-size: 1.5rem;
      margin: 0.7rem 0;
      line-height: 1em;
    }
  }

  .otherServices {
    ul {
      margin-top: 1rem;
      list-style: none;
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 2rem 4rem;
    }
  }
}