@use "../../../styles/colors";

.button {
  font-weight: bold;
  padding: 0.6rem 1.5rem;
  border-radius: 3px;
  outline: none;
  border: 2px solid colors.$primary;
  line-height: 1;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5em;

  &:hover {
    cursor: pointer;
  }

  :global(.icon) {
    font-weight: normal;
  }

  &:disabled {
    color: white;
    background-color: colors.$gray;
    border-color: colors.$gray;

    &:hover {
      cursor: not-allowed;
    }
  }
}

.primary {
  background-color: colors.$primary;
  color: colors.$primary-foreground !important;

  &:hover, &:focus {
    background-color: white;
    color: colors.$primary !important;
  }
}

.secondary {
  background-color: white;
  color: colors.$primary !important;

  &:hover, &:focus {
    background-color: colors.$primary;
    color: colors.$primary-foreground !important;
  }
}

.transparent {
  border-color: transparent;
  background-color: transparent;
  font-weight: normal;

  &:hover {
    border-color: colors.$primary;
  }
}