@use "styles/colors";

span.badge {
  display: inline-block;
  background-color: colors.$tertiary;
  padding: 0 6px;
  font-size: 0.8rem;
  font-weight: 600;
  border-radius: 0.8rem;
  min-width: 1.5em;
  text-align: center;
  margin: 0 0.2rem;
}