@use "colors";
@use "theme";
@import "normalize";

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local(''),
  url('/static/fonts/source-sans-pro-v21-latin-300.woff2') format('woff2'),
  url('/static/fonts/source-sans-pro-v21-latin-300.woff') format('woff');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local(''),
  url('/static/fonts/source-sans-pro-v21-latin-regular.woff2') format('woff2'),
  url('/static/fonts/source-sans-pro-v21-latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local(''),
  url('/static/fonts/source-sans-pro-v21-latin-600.woff2') format('woff2'),
  url('/static/fonts/source-sans-pro-v21-latin-600.woff') format('woff');
}

@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local(''),
  url('/static/fonts/merriweather-v30-latin-300.woff2') format('woff2'),
  url('/static/fonts/merriweather-v30-latin-300.woff') format('woff');
}

@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local(''),
  url('/static/fonts/merriweather-v30-latin-regular.woff2') format('woff2'),
  url('/static/fonts/merriweather-v30-latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local(''),
  url('/static/fonts/merriweather-v30-latin-700.woff2') format('woff2'),
  url('/static/fonts/merriweather-v30-latin-700.woff') format('woff');
}

html,
body {
  background-color: colors.$background;
  padding: 0;
  margin: 0;
  font-family: theme.$font-regular;
  font-size: theme.$font-size;
  line-height: 1.5;
  font-weight: 400;
  color: colors.$text;

  @media screen and (max-width: theme.$breakpoint-sm) {
    font-size: theme.$font-size-sm;
  }
}

body {
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-family: theme.$font-serif;
  margin: 0;
  margin-bottom: 1rem;
}

h2, h3, h4, h5, h6 {
  margin-top: 2rem;
}

h1 {
  font-size: 1.7rem;
  font-weight: 300;
}

h2 {
  font-size: 1.5rem;
  font-weight: normal;
}

h3 {
  font-size: 1.25rem;
  font-weight: normal;
}

h4 {
  font-size: 1rem;
  font-weight: normal;
}

h5 {
  font-size: 1rem;
  font-weight: 300;
}

h6 {
  font-size: 0.875rem;
  font-weight: normal;
}

* {
  box-sizing: border-box;
}

hr {
  border: none;
  border-top: 1px solid colors.$gray-medium;
  height: 1px;
  overflow: hidden;
}

.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-max-width {
  max-width: 800px !important;
}

.text-no-break {
  white-space: nowrap;
}

.text-secondary {
  color: colors.$secondary;
}

.text-muted {
  color: colors.$gray;
}

.text-danger {
  color: colors.$destructive;
}

.full-width-ignore-bounds {
  margin-left: min(-1rem, calc((100vw - 100%) / 2 * -1)) !important;
  margin-right: min(-1rem, calc((100vw - 100%) / 2 * -1)) !important;
}

.inset {
  padding-left: 1rem;
  padding-right: 1rem;
}

p {
  margin-top: 0.5rem;
}

::selection {
  background-color: colors.$secondary-muted;
  color: colors.$text;
}

.selection-on-muted *::selection {
  background-color: colors.$secondary;
  color: white;
}

body.no-scroll {
  &::-webkit-scrollbar {
    display: none;
  }
}

.pannable {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  overflow: hidden !important;
}

.tooltip {
  padding: 0.8rem 1rem !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  z-index: 200 !important;
  max-width: 300px !important;

  a {
    color: inherit !important;
  }
}

.scrollbars {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: colors.$gray;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 20px;
  }
}

.noListStyles {
  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
  }

  ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
  }

  ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
  }

  & > ol > li:before {
    font-size: 1.25rem;
    font-weight: normal;
    font-family: theme.$font-serif;
  }

  li ol > li {
    margin: 0;
    margin-top: 1em;
  }

  li ol > li:before {
    content: counters(item, ".") " ";
  }

  ol.roman > li:before {
    content: "(" counter(item, lower-roman) ")";
  }

  h3{
    margin-top: .5rem;
  }
}

table.profileTable {
  border-collapse: collapse;
  width: 100%;

  &.narrow {
    @media (min-width: theme.$breakpoint-md) {
      max-width: 60%;
    }
  }

  thead {
    color: colors.$secondary;
    text-align: left;
    border-bottom: 1px solid colors.$text;

    th {
      font-weight: normal;
      padding: 0.8rem 1.5rem 0.8rem 0rem;
      max-width: 70ch;

      &:last-child {
        padding-right: 0rem;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid colors.$gray-light;
      vertical-align: top;

      &:last-child {
        border-bottom: none;
      }

      &.intermediate {
        border-bottom-color: transparent;
      }
    }

    th, td {
      padding: 0.8rem 1.5rem 0.8rem 0rem;
      max-width: 70ch;
      text-align: left;
    }

    td:last-child {
      padding-right: 0rem;
    }
  }
}

code {
  font-size: 0.9rem;
  display: inline-block;
  background-color: colors.$gray-light;
  border-radius: 3px;
  padding-inline: 4px;
}

.underlineHeading {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: colors.$secondary;
  padding-bottom: 0.3rem;
  border-bottom: 1px solid colors.$secondary;

  h1, h2, h3, h4, h5, h6 {
    font-size: 1.2rem;
    margin: 0;
    flex: 1;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;

  &.space {
    justify-content: space-between;
  }

  &.center {
    justify-content: center;
  }

  &.start {
    justify-content: flex-start;
  }

  &.end {
    justify-content: flex-end;
  }

  &.wrap {
    flex-wrap: wrap;
  }
}

.dashboard {
  display: block;
  max-width: 1200px;
  margin: 0 auto 0.5rem auto;
  font-family: theme.$font-regular;
  font-size: 1rem;
  font-weight: normal;

  &.narrow {
    max-width: 800px;
  }
}

span.plus {
  color: colors.$secondary;
  display: inline-block;
  border-radius: 3px;
  font-family: theme.$font-serif;
  font-weight: 400;
}

.muted-accent {
  background-color: colors.$gray-light;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
}

.icon {
  display: inline-block;
  position: relative;
  width: 1.2em;
  height: 1.2em;
  margin: 0 0.1em;
  fill: currentColor;

  &.inline {
    width: 1em;
    height: 1em;
    transform: translateY(0.15em);
  }
}

ul.contentList {
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 14px;
}

ul.no-list-style, ol.no-list-style {
  list-style: none;
  margin: 0;
  padding: 0;
}

.react-horizontal-scrolling-menu--inner-wrapper {
  overflow-x: hidden;
}

// ---------------------------------------
// PROGRESS BAR
// ---------------------------------------

#nprogress .bar {
  background-color: colors.$secondary !important;
  height: 5px !important;
  z-index: 200 !important;
}

// blur effect
#nprogress .peg {
  display: none !important;
}

// ---------------------------------------
// Cookie Banner & Widget
// ---------------------------------------

a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
  display: none !important;
}

/* Branding on the Privacy trigger */
#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
  display: none !important;
}

#CookiebotDeclaration > div:nth-child(2) {
  display: none;
}