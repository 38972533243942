@use "../../../styles/colors";

.details {
  display: inline-block;
  cursor: pointer;
  color: colors.$text;

  &[open] {
    width: 100%;
  }

  @media print {
    display: none;
  }
}

.content {
  cursor: auto;
  color: colors.$text;
}