@use "../../../styles/colors";
@use "../../../styles/theme";

.navigation {
  position: relative;
  max-width: calc(1200px);
  margin: 0 auto;
  padding: 0 0.5rem;

  &::before, &::after {
    content: '';
    position: absolute;
    height: 100%;
    background: rgb(255, 255, 255);
    width: 0.5rem;
    top: 0;
    z-index: 10;
  }

  &::before {
    left: 0;
    background: linear-gradient(90deg, white 0%, transparent 100%);
  }

  &::after {
    right: 0;
    background: linear-gradient(90deg, transparent 0%, white 100%);
  }

  @media print {
    display: none;
  }
}

.buttonsVisible {
  padding: 0;

  &::before {
    left: 1.5rem;
  }

  &::after {
    right: 1.5rem;
  }
}

.item {
  font-family: theme.$font-serif;
  cursor: pointer;
  display: inline-block;
  color: colors.$text;
  text-decoration: none;
  padding: 0 0.5rem 7px 0.5rem;
  position: relative;

  button {
    background: transparent;
    border: none;
    padding: 0;
  }

  &::after {
    content: '';
    position: absolute;
    height: 6px;
    bottom: 0;
    left: 0.5rem;
    right: 0.5rem;
  }
}

.item.active {
  color: colors.$secondary;

  button {
    color: colors.$secondary;
  }

  &::after {
    background-color: colors.$secondary;
  }
}

.scrollContainer {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.25rem;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  & > * {
    flex-shrink: 0;
  }
}

button.arrow {
  background-color: transparent;
  border: none;
  width: 2.5rem;
  padding: 0;
  position: relative;
  cursor: pointer;

  :global(.icon) {
    height: 1.5rem;
    width: 1.5rem;
    position: absolute;
    top: 0;
  }
}

button.prev {
  :global(.icon) {
    left: 0.5rem;
  }
}

button.next {
  :global(.icon) {
    right: 0.5rem;
  }
}