@use "styles/colors";

.container {
  position: relative;
  
  input:not([type=checkbox]),
  textarea,
  select {
    display: block;
    width: 100%;
  }

  label {
    user-select: none;
  }

  small {
    position: absolute;
    display: block;
    height: 1.2rem;
    bottom: -1.2rem;
    left: 0;
    right: 0;
    overflow: hidden;
    text-overflow: ellipsis;

    &.error {
      color: colors.$destructive;
    }
  }
}

.checkbox {
  position: relative;

  .row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  input[type=checkbox] {
    display: inline-block;
    margin-right: 0.5rem;
    margin-top: 0.4rem;
    appearance: colors.$secondary;
  }

  small {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    color: colors.$destructive;
  }
}

.standalone {
  margin-bottom: 1.2rem;
}