@use "../../../styles/colors";
@use "../../../styles/theme";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 0;
  overflow: hidden;
}

.pagination {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  button {
    outline: none;
    background: transparent;
    border: 1px solid colors.$primary;
    margin: 0 0.2rem;

    &:hover:not(:disabled),
    &:focus:not(:disabled),
    &.active {
      background-color: colors.$secondary;
      border-color: colors.$secondary;
      color: white;
      cursor: pointer;
    }

    &:disabled:hover {
      cursor: not-allowed;
    }
  }

  button.chevron {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
  }

  button.page {
    padding: 1rem;
    font-family: theme.$font-serif;
    line-height: 1;
    min-width: 3rem;
    height: 3rem;
  }

  span.more {
    margin: -0.75rem 0.5rem 0 0.5rem;
    height: 1rem;
    color: colors.$primary;
  }
}