@use "styles/colors";

.textDivider {
  width: 100%;
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;

  > span {
    position: relative;
    display: inline-block;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 60%;
      width: 9999px;
      height: 1px;
    }

    &:before {
      right: 100%;
      margin-right: 15px;
    }

    &:after {
      left: 100%;
      margin-left: 15px;
    }
  }
}

.default {
  > span {
    color: colors.$text;

    &:before,
    &:after {
      background: rgba(0, 0, 0, 0.2);
    }
  }
}

.secondary {
  > span {
    color: colors.$secondary;

    &:before,
    &:after {
      background: colors.$secondary-muted;
    }
  }
}