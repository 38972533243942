@use "styles/colors";
@use "styles/theme";

.form {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  align-items: end;
  max-width: 1000px;
  margin: 0 auto;

  select {
    border: 1px solid colors.$gray;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2) inset;
  }

  .grid4 {
    grid-column: 1 / 5;
  }

  .grid2 {
    grid-column: span 2 / auto;
  
    @media (max-width: theme.$breakpoint-sm)
    {
      grid-column: 1 / 5;
    }
  }

  .grid3 {
    grid-column: span 3 / auto;

    @media (max-width: theme.$breakpoint-sm)
    {
      grid-column: 1 / 5;
    }
  }

  .grid1 {
    grid-column: span 1 / auto;
  
    @media (max-width: theme.$breakpoint-sm)
    {
      grid-column: span 2 / auto;
    }
  
    @media (max-width: theme.$breakpoint-xs)
    {
      grid-column: 1 / 5;
    }
  }
}

.sitz {
  display: flex;
  flex-direction: row;
  align-items: center;

  input:nth-child(1) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 25%;
  }

  input:nth-child(2) {
    border-radius: 0;
    border-left-width: 0;
    width: 25%;
  }

  input:nth-child(3) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-width: 0;
    width: 50%;
  }
}

.actions {
  max-width: 1000px;
  margin: 1rem auto 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 1rem;
}
