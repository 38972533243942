@use "styles/colors";
@use "styles/theme";

.actionLinks {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.dropdown {
  background-color: white;
  padding: 0.5rem 1rem;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  min-width: 10rem;
  font-size: 0.9rem;
  z-index: 101;

  div[role=menuitem] {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 0.2rem 0rem;

    :global(.icon) {
      margin-left: 0.5rem;
    }

    &:hover, &:focus {
      outline: none;
      color: colors.$secondary;
      cursor: pointer;
    }
  }
}

.trigger {
  background-color: transparent;
  color: colors.$secondary;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  outline: none;
  border: none;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
}