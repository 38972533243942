@use "../../../styles/colors";

$factors: ("xs": 0.6, "sm": 1, "md": 1.5, "lg": 2);

@each $name, $factor in $factors {

  .container-#{$name} {
    display: inline-block;
    width: calc(16px * $factor);
    height: calc(20px * $factor);
  }

  .bars-#{$name} {
    width: calc(4px * $factor);
    height: calc(20px * $factor);
    border-radius: calc(2px * $factor);
    display: block;
    position: relative;
    animation: bounce-middle-#{$name} 0.6s ease 0.1s infinite;
    background-color: currentColor;
    margin: 0 auto;

    &:after {
      content: "";
      position: absolute;
      display: block;
      top: 0px;
      width: calc(4px * $factor);
      height: calc(20px * $factor);
      border-radius: calc(2px * $factor);
      right: calc(-6px * $factor);
      top: 50%;
      transform: translateY(calc(-10px * $factor)) translateZ(0);
      animation: bounce-middle-#{$name} 0.6s ease 0.2s infinite;
      background-color: currentColor;
    }

    &:before {
      content: "";
      position: absolute;
      display: block;
      top: 0px;
      width: calc(4px * $factor);
      height: calc(20px * $factor);
      border-radius: calc(2px * $factor);
      left: calc(-6px * $factor);
      top: 50%;
      transform: translateY(calc(-10px * $factor)) translateZ(0);
      animation: bounce-middle-#{$name} 0.6s ease 0s infinite;
      background-color: currentColor;
    }
  }

  @keyframes bounce-middle-#{$name} {
    0% {
      height: calc(4px * $factor);
      margin-top: calc(8px * $factor);
      margin-bottom: calc(8px * $factor);
    }
    50% {
      height: calc(20px * $factor);
      margin-top: 0px;
      margin-bottom: 0px;
    }
    100% {
      height: calc(4px * $factor);
      margin-top: calc(8px * $factor);
      margin-bottom: calc(8px * $factor);
    }
  }
}

.auto,
.auto:before,
.auto:after {
  background-color: currentColor;
}

.primary,
.primary:before,
.primary:after {
  background-color: colors.$primary;
}

.secondary,
.secondary:before,
.secondary:after {
  background-color: colors.$secondary;
}

.primary-foreground,
.primary-foreground:before,
.primary-foreground:after {
  background-color: colors.$primary-foreground;
}