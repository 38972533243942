@use "styles/colors";

ul.container {
  list-style: none;
  margin: 0;
  margin-top: -1rem;
  padding: 0;

  li:last-child {
    a.item {
      border-bottom-color: transparent;
    }
  }
}

a.item {
  text-decoration: none;
  color: colors.$text;
  background-color: colors.$secondary-muted;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  border-bottom: 1px solid colors.$gray;

  &:hover {
    background-color: darken(colors.$secondary-muted, 5%);
  }

  .iconContainer {
    background-color: colors.$secondary;
    border-radius: 50%;
    box-sizing: border-box;
    border: 1px solid colors.$secondary;
    color: white;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.2rem;
    height: 2.2rem;
    flex-shrink: 0;
  }

  .content {
    flex: 1;
    margin-inline: 0.8rem;
  
    span.name {
      font-weight: bold;
      display: block;
      margin-top: 0.3rem;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin-top: 0.5rem;
        line-height: 1.1rem;
      }
    }
  }

  span.details {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0.1rem;
    span {
      text-decoration: underline;
    }
  }

  &.read {
    background-color: transparent;

    &:hover {
      background-color: lighten(colors.$gray-light, 5%);
    }

    .iconContainer {
      background-color: transparent;
      border-color: colors.$gray;
      color: colors.$text;
    }
  }
}