@use "../../../styles/theme";

.logo {
  width: 250px;
  margin-bottom: -6.5px;

  @media screen and (max-width: theme.$breakpoint-md) {
    width: 200px;
  }

  @media screen and (max-width: theme.$breakpoint-sm) {
    width: 150px;
  }
}