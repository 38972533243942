@use "styles/colors";
@use "styles/theme";

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeInUp {
  0% { opacity: 0; transform: translateY(2rem); }
  100% { opacity: 1; transform: translateY(0); }
}

.overlay {
  background: rgba(0 0 0 / 0.4);
  backdrop-filter: blur(6px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  overflow-y: auto;
  z-index: 1000;
  animation: fadeIn 0.1s ease-in;
}

.content {
  background-color: white;
  max-width: 660px;
  width: 100%;
  border-radius: 10px;
  padding: 2rem;
  position: relative;
  animation: fadeInUp 0.15s ease-in;
  max-height: 100vh;
  overflow: hidden;

  hr {
    margin: 0;
  }

  h2 {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

    & > svg {
      border-radius: 50%;
      padding: 0.2rem;
      fill: white;
    }

    &.success > svg {
      background-color: colors.$secondary;
    }
  }
}

.innerContent {
  max-height: 75vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  margin: 0 -0.5rem;
  padding: 0 0.5rem;

  & > * {
    width: 100%;
  }
}

button.close {
  background-color: transparent;
  outline: none;
  border: none;
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  margin: 0;
  padding: 0.5rem;
  color: colors.$gray;

  &:hover, &:focus {
    color: black;
    cursor: pointer;
  }
}

p.legal {
  color: colors.$gray;
  font-size: 0.9rem;
  margin-top: 0;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin-top: 2rem;

  @media (max-width: theme.$breakpoint-sm) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

p.error {
  color: colors.$destructive;
}

// --------------------------------------------
// TOKEN BANNER
// --------------------------------------------

div.tokenBanner {
  background-color: colors.$tertiary-muted;
  padding: 1rem 2rem;
  border-radius: 10px;
  margin-bottom: 2rem;
  font-size: 1rem;
  font-family: theme.$font-serif;

  p {
    margin: 0;
  }
}