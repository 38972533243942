@use "styles/colors";
@use "styles/theme";

.filterContainer{
    max-width: 800px;
    margin: 1rem auto;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    .filterForm{
        display:flex;
        flex-direction: row;
        flex-wrap: wrap;
        @media (max-width: theme.$breakpoint-sm) {
            flex-direction: column;
            align-items: center;
        }
    }

    .toggleButtonsContainer{
        display: flex;
        flex-direction: row;
        gap: .5rem;
        color: colors.$secondary;

        a {
            color: colors.$secondary;
        }
    }
}

.toggle{
    position:relative;
}
.label{
    display:flex;

    flex-basis: 50%;
    gap:1rem;
    align-items: center;
    cursor: pointer;
    input.toggle{
        height: 16px !important;
    }
    input.toggle::before{
        top:0px
    }
    input.toggle::after{
        top:3px
    }
    @media (max-width: theme.$breakpoint-sm) {
        flex-direction: row;
        width: min(100%,300px);
        padding: 0.25rem;
        order:unset !important;
    }
}
.filterButton{
    &.selected{
        background-color: darken(colors.$secondary-muted, 5%);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4) inset;
    }
    border-radius: 3px;
    background-color: white;
    display: grid;
    place-content: center;
    text-decoration: none;
    color: inherit;
    padding: 0.5rem;
    border: 1px solid colors.$primary;
    cursor: pointer;
}
.flexLink{
    display:flex;
    align-items: center;
    gap: 0.25rem;
}
.filterButtonContainer{
    display:flex;
    justify-content: flex-start;
    max-width: 800px;
    margin: 1rem auto;
    @media (max-width: theme.$breakpoint-sm) {
        justify-content: center;
    }
}
