@use "../../../styles/colors";

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5rem 0rem;

  button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0rem 1rem;
    outline: none;
    border: none;
    background-color: transparent;
    color: colors.$secondary;

    &:hover, &:focus {
      cursor: pointer;
    }
  }

  span.line {
    display: block;
    height: 1px;
    border-bottom: 1px solid colors.$secondary;
    flex-shrink: 1;
    flex-grow: 1;
  }
}