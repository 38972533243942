.layout {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  min-height: 100vh;
  justify-content: space-between;

  .contentContainer {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    &> * {
      width: 100%;
    }
  }

  &> * {
    width: 100%;
  }
}

main.content {
  margin: 0 auto;
  max-width: 1200px;
  min-height: 36.5vh;
  padding: 0 1rem;

  hr {
    margin: 3rem 0;
  }
}

.searchContainer {
  padding: 0 1rem;
}