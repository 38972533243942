@use "styles/theme";
@use "styles/colors";

.container {
  max-width: 1000px;
  margin: 0 auto;
}

.actions {
  margin-bottom: 3rem;
}

.labelQuick {
  display: block;
  max-width: 800px;
  margin: 0 auto 0.5rem auto;
}

.labelDetail {
  display: block;
  max-width: 1000px;
  margin: 0 auto 1rem auto;
}