@use "styles/colors";
@use "styles/theme";

.header {
  padding: 1rem 0;
  border-bottom: 1px solid colors.$gray-light;
  background-color: white;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  gap: 1rem;
  padding: 0 1rem;
  
  &>a {
    position: relative;
  }
}

nav.anchors {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;

  &>a, &>button {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    padding: 0;
    background-color: transparent;
    border: none;
    
    &:hover {
      cursor: pointer;
    }
  }

  @media screen and (max-width: theme.$breakpoint-sm)
  {
    gap: 1rem;

    span {
      display: none;
    }

    &>a, &>button {
      font-size: 1.5em;
    }
  }

  .user {
    color: colors.$secondary;
  }

  .search-active {
    font-weight: bold;
  }
}