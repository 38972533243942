@use "styles/colors";
@use "styles/theme";

.container {
  margin-left: 13rem;
  margin-top: 2rem;

  @media screen and (max-width: theme.$breakpoint-md) {
    margin-left: 0;
  }
  padding: 2rem;
  background-color: colors.$secondary-muted;
  border-radius: 5px;

  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

  h3 {
    color: colors.$secondary;
    margin-top: 0;
  }

  .splitContainer {
    display: flex;
    flex-direction: row;

    h4 {
      margin-top: 0;
    }

    .fbNrUploadContainer {
      flex-basis: 50%;
      display: flex;
      flex-direction: column;

      .form {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        textarea {
          width: 100%;
          max-width: 100%;
          resize:vertical;
        }

        .checkbox{
          margin-top: -.75rem;
        }
      }

      .confirmation {
        text-align: center;
        font-weight: bold;
        flex-grow: 1;

        display: flex;
        flex-direction: column;
        justify-content: space-between;


        .warn{
            color: colors.$destructive;
        }
        .confirmationButtons {
          margin-top:2rem;
          display: flex;
          flex-direction: row;

          button {
            flex-basis: 50%;
          }
        }
      }
    }

    .divider {
      flex-basis: 1px;
      margin-inline: 2.5rem;
      margin-top: 1.5rem;
      border-right: 1px solid colors.$secondary;
    }

    .profileText {
      flex-basis: 50%;

      p {
        margin: 0;
      }
    }


    @media screen and (max-width: theme.$breakpoint-sm) {
      flex-direction: column-reverse;
      .divider {
        flex-basis: 1px;
        margin-inline: 0;
        margin-block: 2rem;
        border-top: 1px solid colors.$secondary;
      }
    }

  }
}