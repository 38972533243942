@use "../../../styles/colors";

.disclaimer {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: colors.$secondary-muted;
  padding: 1rem;
  border-radius: 0.5rem;
  color: colors.$text;
  text-decoration: none;

  :global(.icon) {
    flex-shrink: 0;
    font-size: 2rem;
  }

  p {
    margin: 0;
    margin-left: 1rem;

    &::selection {
      background-color: colors.$secondary;
      color: white;
    }
  }
}