@use "styles/colors";
@use "styles/theme";

.container {
  max-width: 800px;
  margin: 0 auto;

  padding-top: 50px;
  margin-top: -50px;

  input {
    font-family: theme.$font-serif;
    line-height: 1.2;
    font-size: 1rem;
  }

  input[readonly],
  select[disabled] {
    color: colors.$text;
    font-family: theme.$font-serif;
    background-color: colors.$background;
    opacity: 1;
    font-size: 1rem;
    background-image: none;
    border-color: transparent;
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px colors.$background inset !important;
      box-shadow: 0 0 0 30px colors.$background inset !important;
      border-color: colors.$background !important;
    }
  }
}

.sectionHeading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  h2,
  a {
    margin-top: 0;
    margin-bottom: 1rem;
  }
}

.sectionActions {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;

  a {
    margin-right: 2rem;
  }
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem 1rem;
  align-items: end;

  .grid4 {
    grid-column: 1 / 5;
  }

  .grid2 {
    grid-column: span 2 / auto;

    @media (max-width: theme.$breakpoint-sm) {
      grid-column: 1 / 5;
    }
  }

  .grid1 {
    grid-column: span 1 / auto;

    @media (max-width: theme.$breakpoint-sm) {
      grid-column: span 2 / auto;
    }

    @media (max-width: theme.$breakpoint-xs) {
      grid-column: 1 / 5;
    }
  }
}

.kostenContainer {
  .banner {
    background-color: colors.$secondary-muted;
    border-radius: 10px;
    padding: 1.5rem 2rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
    user-select: none;
    margin-top: 2rem;

    span {
      font-size: 2.5rem;
      font-weight: bold;
      line-height: 2.5rem;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  dl.breakdown {
    margin-top: 2rem;

    .coupon {
      display: flex;
      padding: 0.5rem 1rem;
      background-color: colors.$tertiary-muted2;
      border-radius: 10px;
      font-weight: bold;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      user-select: none;

      button {
        background-color: transparent;
        border: none;
        outline: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .couponInput {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 0.5rem;

      input {
        display: block;
      }

      button {
        gap: 0.5rem;
      }

      small {
        color: colors.$destructive;
      }
    }

    .discount {
      margin-left: 1rem;
      font-weight: bold;
    }

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid colors.$gray-light;
      padding: 1rem 0;
      flex-wrap: wrap;
      gap: 0.5rem;

      &:last-child {
        border-bottom: none;
        font-weight: bold;
        font-size: 1.2em;
      }

      dt {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}

.paymentForm {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .sectionHeading {
    h2 {
      margin: 0;
    }

    margin: 0;
  }

  &>* {
    width: 100%;
  }
}

.paymentProviders {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
  margin-top: 1rem;
}

.manageAccount {
  display: inline-block;
}

.upgradeContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 80%;
    height: 3rem;
  }
}

.dangerButton {
  button {
    background-color: colors.$destructive;
    border-color: colors.$destructive;

    &:hover, &:focus {
     color: colors.$destructive !important;
    }
  }
}

.saveButtonContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    padding-inline: 2.25rem;
  }
}