@use "styles/colors";

.error {
  color: colors.$destructive;
  display: block;
  text-align: center;
}

.options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  div.option {
    flex: 1;
    max-width: 16rem;

    &:first-child label {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-left: 1px solid colors.$primary;
    }

    &:last-child label {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  label {
    display: block;
    text-align: center;
    padding: 0.6rem 1.5rem;
    border: 1px solid colors.$primary;
    border-left: none;
    background-color: transparent;
    color: colors.$primary;
    user-select: none;
    cursor: pointer;
    font-weight: bold;
  }

  input[type=radio] {
    display: none;
  }

  input[type=radio]:checked + label {
    background-color: darken(colors.$secondary-muted, 5%);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4) inset;
    cursor: auto;
  }

  input[type=radio]:disabled + label {
    cursor: not-allowed;
  }
}