@use "styles/colors";

.container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: colors.$background;
}

.content {
  background-color: colors.$secondary-muted;
  padding: 5rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoContainer {
  align-self: center;
  margin: 0 auto 2rem auto;
}