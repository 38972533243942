@use "styles/colors";

.content {
  background-color: colors.$secondary-muted;
  padding: 0.5rem 0.7rem;
  border-radius: 3px;
  max-width: min(23rem, 100vw);
  user-select: none;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.25);
  z-index: 1000;

  &.plus {
    background-color: colors.$tertiary-muted;

    .arrow {
        fill: colors.$tertiary-muted;
    }
  }

  p {
    margin-top: 0;
  }

  .title {
    font-weight: bold;
    margin-bottom: 0.4rem;
  }

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 1rem;
  }
}

.arrow {
  fill: colors.$secondary-muted;
}

button.dismiss {
  background-color: transparent;
  border: none;
  outline: none;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}