@use "styles/colors";

.iconButton {
  outline: none;
  border: none;
  padding: 0.5rem;
  color: colors.$gray;

  &:hover, &:focus {
    color: colors.$text;
    cursor: pointer;
  }

  &:disabled {
    background-color: colors.$gray-light;

    &:hover {
      cursor: not-allowed;
    }
  }
}