@use "styles/colors";

.content {
  background-color: colors.$primary;
  color: colors.$primary-foreground;
  font-size: 0.9rem;
  padding: 0.1em 0.5em;
  border-radius: 3px;
}

.arrow {
  fill: colors.$primary;
}