@use "styles/colors";
@use "styles/theme";

.container {
  border-bottom: 1px solid colors.$secondary;
  position: sticky;
  top: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.2rem;
  background-color: colors.$background;
  margin-bottom: 2rem;
  z-index: 10;
}

.narrow {
  max-width: 800px !important;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: theme.$breakpoint-sm)
  {
    flex-direction: column;
    align-items: flex-start;
  }

  h1 {
    margin: 0;
    font-size: 1.5rem;
    color: colors.$secondary;
    font-weight: normal;
    flex-grow: 1;
  }

  &>* {
    margin-right: 1rem;
    margin-bottom: 0.5rem;

    &:nth-last-child(2) {
      margin-right: 0;
    }
  }
}

.descriptionContainer {
  margin-top: -1.5rem;
  margin-bottom: 4rem;

  p {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
  }
}