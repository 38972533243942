@use "styles/colors";
@use "styles/theme";

.root {
  display: flex;
  flex-direction: row;
}

.list {
  min-width: 12rem;
  margin-right: 1rem;
}

.trigger {
  padding-top: 0.8rem;
  border-bottom: 1px solid colors.$gray-light;
  font-family: theme.$font-serif;

  span {
    display: inline-block;
    padding-bottom: calc(0.8rem - 6px);
    border-bottom: 6px solid transparent;
  }

  &:hover, &:focus {
    color: colors.$secondary;
    cursor: pointer;
  }

  &[aria-selected=true] {
    color: colors.$secondary;
    border-bottom-color: colors.$secondary;

    span {
      border-bottom-color: colors.$secondary;
    }
  }
}

.content {
  flex: 1;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  &>* {
    margin-bottom: 0.5rem;
  }
}